import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'truncateFront' })
export class TruncateFrontPipe implements PipeTransform {
  transform(value: string, length: number): string {
    length = length || 8;

    let newValue = value.substr(value.length - length);

    if(value.length > length) {
      newValue = '...' + newValue;
    }

    return newValue;
  }
}
