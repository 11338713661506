import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Component
import { AppListComponent } from './pages/app-list/app-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DiscoverComponent } from './pages/discover/discover.component';
import { LaunchComponent } from './pages/launch/launch.component';
import { MiningComponent } from './pages/mining/mining.component';
import { VotingComponent } from './pages/voting/voting.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'discover',
    component: DiscoverComponent
  },
  {
    path: 'launch',
    component: LaunchComponent
  },
  {
    path: 'voting',
    component: VotingComponent
  },
  {
    path: 'mining',
    component: MiningComponent
  },
  {
    path: 'app',
    component: AppListComponent
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
