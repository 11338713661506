import { Component, OnInit, ViewChild } from '@angular/core';
import { TransactionService } from '../../../services/transaction.service';
import { Transaction } from '../../../models/transaction';
import { AccountService } from '../../../services/account.service';

@Component({
  selector: 'app-transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.scss']
})
export class TransactionModalComponent implements OnInit {

  @ViewChild('transactionModal') transactionModal: any;
  transactions: Transaction[];

  constructor(
    private transactionService: TransactionService,
    private accountService: AccountService,
  ) { }

  showModal() {
    this.transactionModal.show();
  }

  ngOnInit() {
      this.transactionService.getAllTransactions()
        .subscribe((transactions) => {
          this.transactions = transactions;
          Transaction.updateTransactionInfo(this.transactions, this.accountService.getUser().address);
        });
  }

}
