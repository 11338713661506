import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-send-modal',
  templateUrl: './send-modal.component.html',
  styleUrls: ['./send-modal.component.scss']
})
export class SendModalComponent implements OnInit {

  @ViewChild('sendModal') sendModal: any;

  constructor(
  ) { }

  showModal() {
    this.sendModal.show();
  }

  ngOnInit() {
  }

}
