import { Component, OnInit, Input } from '@angular/core';
import { AssetService } from '../../../services/asset.service';
import { Asset } from '../../../models/asset';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit {

  constructor(private assetSerivce: AssetService) { }

  @Input() assets: Asset[];
  @Input() title: string;

  // Resources

  // Boolean
  showGrid: boolean;

  // Properties
  categories: string[];

  ngOnInit() {
    // Mock data
    this.categories = ['Entertainment', 'Finance', 'Retail', 'Technology'];
  }

  toggleShowGrid() {
    this.showGrid = !this.showGrid;
  }
}
