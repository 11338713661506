import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { SettingsModalComponent } from '../../shared/components/settings-modal/settings-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() navClick = new EventEmitter<string>();
  @ViewChild(SettingsModalComponent) settingsModalComponent: SettingsModalComponent;

  constructor() {

  }

  openSettings() {
    this.settingsModalComponent.showModal();
  }

  ngOnInit() {

  }
}
