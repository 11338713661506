export class Asset {
  name: string;
  desc: string;
  currency: string;
  maximum: string;
  precision: number;
  strategy: string;
  quantity: string;
  height: number;
  issuerId: string;
  acl: number;
  writeoff: number;
  allowWriteoff: number;
  allowWhitelist: number;
  allowBlacklist: number;
  maximumShow: string;
  quantityShow: string;
}
