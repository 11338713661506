import { NgModule } from '@angular/core';

// Modules
import { CoreModule } from '../common/core.module';
import { AppSharedModule } from '../shared/shared.module';

// Components
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [
    HeaderComponent,
    LayoutComponent,
    SidebarComponent
  ],
  imports: [
    AppSharedModule,
    CoreModule
  ],
  providers: [],
  bootstrap: [],
  exports: [
    HeaderComponent,
    LayoutComponent,
    SidebarComponent
  ]
})
export class LayoutModule { }
