import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-launch-modal',
  templateUrl: './launch-modal.component.html',
  styleUrls: ['./launch-modal.component.scss']
})
export class LaunchModalComponent implements OnInit {

  @ViewChild('launchModal') launchModal: any;

  constructor(
  ) { }

  showModal() {
    this.launchModal.show();
  }

  ngOnInit() {
  }

}
