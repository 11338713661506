import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// Component
import { LoginComponent } from './login/login.component';

// Module
import { AppRoutingModule } from '../app-routing.module';

// Services
import { AccountService } from '../services/account.service';
import { AssetService } from '../services/asset.service';
import { TransactionService } from '../services/transaction.service';

@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgxChartsModule
  ],
  declarations: [
    LoginComponent
  ],
  providers: [
    AccountService,
    AssetService,
    TransactionService
  ],
  exports: [
    AppRoutingModule,
    LoginComponent,
    NgxChartsModule
  ]
})
export class CoreModule {}
