import { Component, OnInit, ViewChild } from '@angular/core';

// Services
import { TransactionService } from '../../services/transaction.service';
import { Transaction } from '../../models/transaction';
import { TransactionModalComponent } from '../../shared/components/transaction-modal/transaction-modal.component';
import { AssetService } from '../../services/asset.service';
import { Asset } from '../../models/asset';
import { AccountService } from '../../services/account.service';
import { SendModalComponent } from '../../shared/components/send-modal/send-modal.component';
import { DepositModalComponent } from '../../shared/components/deposit-modal/deposit-modal.component';
import { CoinDetailsModalComponent } from '../../shared/components/coin-details-modal/coin-details-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(TransactionModalComponent) transactionModal: TransactionModalComponent;
  @ViewChild(SendModalComponent) sendModal: SendModalComponent;
  @ViewChild(DepositModalComponent) depositModal: DepositModalComponent;
  @ViewChild(CoinDetailsModalComponent) coinDetailsModal: CoinDetailsModalComponent;

  // Resources
  myAssets: Asset[];
  nasgoCoinData: any[] = [];
  ownedAssets: Asset[];
  recentTransactions: Transaction[] = [];

  constructor(
    private accountService: AccountService,
    private assetService: AssetService,
    private transactionService: TransactionService
  ) {}

  ngOnInit() {
    this.getTransactions();
    this.getMyAssets();
    this.getOwnedAssets();
  }

  openSendModal() {
    this.sendModal.showModal();
  }

  openDepositModal() {
    this.depositModal.showModal();
  }

  openCoinDetails() {
    const tempAsset = new Asset();
    tempAsset.currency = 'Nasgo.NSG';
    tempAsset.quantity = '10000000000';
    this.coinDetailsModal.showModal(tempAsset);
  }

  getTransactions() {
    this.transactionService
      .getAllTransactions(8)
      .subscribe((transactions: any) => {
        this.recentTransactions = transactions;
        Transaction.updateTransactionInfo(
          this.recentTransactions,
          this.accountService.getUser().address
        );
      });
  }

  getMyAssets() {
    this.assetService.getMyAssets().subscribe(assets => {
      console.log('assets', assets);
      this.myAssets = assets.balances;
    });
  }

  getOwnedAssets() {
    this.assetService.getOwnedAssets().subscribe(assets => {
      this.ownedAssets = assets;
    });
  }

  openTransaction() {
    this.transactionModal.showModal();
  }
}
