import { Component, OnInit, ViewChild } from '@angular/core';
import { TransactionModalComponent } from '../transaction-modal/transaction-modal.component';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class SettingsModalComponent implements OnInit {

  @ViewChild(TransactionModalComponent) transactionModal: TransactionModalComponent;

  @ViewChild('settingsModal') settingsModal: any;

  constructor(
  ) { }

  showModal() {
    this.settingsModal.show();
  }

  showTransactionModal() {
    this.transactionModal.showModal();
  }

  ngOnInit() {
  }

}
