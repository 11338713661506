import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-deposit-modal',
  templateUrl: './deposit-modal.component.html',
  styleUrls: ['./deposit-modal.component.scss']
})
export class DepositModalComponent implements OnInit {

  @ViewChild('depositModal') depositModal: any;

  address = 'NNpAxeYeVRmPfmDM9eW5Agzfq2NjmbERar';

  constructor(
  ) { }

  showModal() {
    this.depositModal.show();
  }

  ngOnInit() {
  }

  copyAddress() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.address;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
