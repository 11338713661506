import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Account } from '../models/account';

@Injectable()
export class AccountService {

  account: Account;
  publicKey: string;

  constructor(
    private httpClient: HttpClient
  ) { }

  login(password: string) {
    return this.httpClient.post(
      'https://wallet.nasgo.com/api/accounts/open2',
      {
        publicKey: this.generatePublicKey(password)
      }
    )
      .pipe(
        map((data: any) => {
          this.account = data.account;
          return data;
      }));
  }

  getUser() {
    return this.account;
  }

  generatePublicKey(password: string) {

    // Code generation here
    const publicKey = '8fde6493d75947d1c0a0e4a9693a4aa4f8a5780314b27289d1351793078f3d99';
    this.publicKey = publicKey;
    return this.publicKey;
  }

  getPublicKey() {
    return this.publicKey;
  }
}
