import { Component, OnInit, ViewChild } from '@angular/core';
import { TransactionModalComponent } from '../shared/components/transaction-modal/transaction-modal.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  @ViewChild(TransactionModalComponent) transactionModal: TransactionModalComponent;

  constructor() { }

  ngOnInit() {
  }

  onNavClick(action: string) {
    switch(action) {
      case 'transaction':
        this.transactionModal.showModal();
        break;
    }
  }
}
