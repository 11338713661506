import { Component, OnInit, ViewChild } from '@angular/core';
import { Asset } from '../../../models/asset';
import { AccountService } from '../../../services/account.service';
import { TransactionService } from '../../../services/transaction.service';
import { Transaction } from '../../../models/transaction';

@Component({
  selector: 'app-coin-details-modal',
  templateUrl: './coin-details-modal.component.html',
  styleUrls: ['./coin-details-modal.component.scss']
})
export class CoinDetailsModalComponent implements OnInit {

  @ViewChild('coinDetailsModal') coinDetailsModal: any;

  address: string;
  asset: Asset;
  transactions: Transaction[];

  constructor(
    private accountService: AccountService,
    private transactionService: TransactionService
  ) { }

  showModal(asset: Asset) {
    this.asset = asset;
    this.coinDetailsModal.show();

    // Should be get transactions by coin
    this.transactionService.getTransactionsByCoin()
      .subscribe((transactions: Transaction[]) => {
        this.transactions = transactions;
        Transaction.updateTransactionInfo(this.transactions, this.accountService.getUser().address);
      });
  }

  ngOnInit() {
    this.address = this.accountService.getUser().address;
  }

  copyAddress() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.address;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
