export class Transaction {
  id: string;
  height: string;
  blockId: string;
  type: number;
  timestamp: number;
  senderPublicKey: string;
  senderId: string;
  recipientId: string;
  amount: number;
  fee: number;
  signature: string;
  signSignature: string;
  signatures: any;
  confirmations: string;
  args: any[];
  message: string;
  asset: any;

  // enhanced properties
  action: string;
  sentDate: Date;

  public static updateTransactionInfo(
    transactions: Transaction[],
    userId: string
  ) {
    transactions.forEach((transaction: Transaction) => {

      transaction.sentDate = new Date(transaction.timestamp);

      switch (transaction.type) {
        case 14:
        case 0:
          if (transaction.recipientId === userId) {
            transaction.action = 'Received';
          }
          if (transaction.senderId === userId) {
            transaction.action = 'Sent';
          }
          break;
        case 13:
          transaction.action = 'Launch';
          break;
        case 10:
          transaction.action = 'Register';
          break;
        case 9:
          transaction.action = 'Register Issuer';
          break;
        case 3:
          transaction.action = 'Vote';
          break;
        case 2:
          transaction.action = 'Delegate';
          break;
      }
    });
  }
}
