import { Component, OnInit } from '@angular/core';

// Models
import { Asset } from '../../models/asset';
import { AssetService } from '../../services/asset.service';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent implements OnInit {

  newAssets: Asset[];

  constructor(
    private assetService: AssetService
  ) { }

  ngOnInit() {
    this.assetService.getNewAssets()
      .subscribe((assets) => {
        this.newAssets = assets;
      });
  }
}
