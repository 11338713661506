import { NgModule } from '@angular/core';
import { NgStickyDirective } from 'ng-sticky';

// Pipes
import { CoinAmountPipe } from './pipes/coin-amount.pipe';
import { TruncateFrontPipe } from './pipes/truncate-front.pipe';

// Modules
import { CoreModule } from '../common/core.module';
import { ModalModule } from 'ngx-bootstrap/modal';

// Components
import { AssetListComponent } from './components/asset-list/asset-list.component';
import { CoinDetailsModalComponent } from './components/coin-details-modal/coin-details-modal.component';
import { DepositModalComponent } from './components/deposit-modal/deposit-modal.component';
import { GetNsgModalComponent } from './components/get-nsg-modal/get-nsg-modal.component';
import { MyAssetsComponent } from './components/my-assets/my-assets.component';
import { LaunchModalComponent } from './components/launch-modal/launch-modal.component';
import { SendModalComponent } from './components/send-modal/send-modal.component';
import { SettingsModalComponent } from './components/settings-modal/settings-modal.component';
import { SparklineChartComponent } from './components/sparkline-chart/sparkline-chart.component';
import { TransactionModalComponent } from './components/transaction-modal/transaction-modal.component';

@NgModule({
  declarations: [
    AssetListComponent,
    CoinAmountPipe,
    CoinDetailsModalComponent,
    DepositModalComponent,
    GetNsgModalComponent,
    MyAssetsComponent,
    LaunchModalComponent,
    NgStickyDirective,
    SendModalComponent,
    SettingsModalComponent,
    SparklineChartComponent,
    TransactionModalComponent,
    TruncateFrontPipe
  ],
  imports: [CoreModule, ModalModule.forRoot()],
  providers: [],
  bootstrap: [],
  exports: [
    AssetListComponent,
    CoinAmountPipe,
    CoinDetailsModalComponent,
    DepositModalComponent,
    GetNsgModalComponent,
    MyAssetsComponent,
    LaunchModalComponent,
    NgStickyDirective,
    SendModalComponent,
    SettingsModalComponent,
    SparklineChartComponent,
    TransactionModalComponent,
    TruncateFrontPipe
  ]
})
export class AppSharedModule {}
