import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { Asset } from '../models/asset';
import { Account } from '../models/account';
import { AccountService } from './account.service';

@Injectable()
export class AssetService {

  constructor(
    private accountService: AccountService,
    private httpClient: HttpClient
  ) {  }

  getNewAssets() {
    return this.httpClient.get<Asset[]>('./assets/json/new-assets.json');
  }

  getMyAssets(limit?: number, offset?: number) {
    limit = limit || 100;
    offset = offset || 0;

    const url = 'https://wallet.nasgo.com/api/uia/balances/' +
    `${this.accountService.getUser().address}?limit=${limit}&offset=${offset}`;

    return this.httpClient.get<{balances: Asset[]}>(url);
  }

  getOwnedAssets() {
    return this.httpClient.get<Asset[]>('./assets/json/owned-assets.json');
  }
}
