import { Component, OnInit, ViewChild, Input } from '@angular/core';

// Models
import { Asset } from '../../../models/asset';

// Components
import { CoinDetailsModalComponent } from '../coin-details-modal/coin-details-modal.component';
import { DepositModalComponent } from '../deposit-modal/deposit-modal.component';
import { SendModalComponent } from '../send-modal/send-modal.component';

@Component({
  selector: 'app-my-assets',
  templateUrl: './my-assets.component.html',
  styleUrls: ['./my-assets.component.scss']
})
export class MyAssetsComponent implements OnInit {

  @Input() title: string;
  @Input() hideLogo: boolean;
  @Input() assetData: Asset[];
  @Input() buttonName: string;
  @Input() imageSrc = 'assets/img/icons/test-blank.png';
  @Input() route: string;

  @ViewChild(SendModalComponent) sendModal: SendModalComponent;
  @ViewChild(DepositModalComponent) depositModal: DepositModalComponent;
  @ViewChild(CoinDetailsModalComponent) coinDetailsModal: CoinDetailsModalComponent;

  // Booleans
  showGrid = true;

  constructor() { }

  ngOnInit() {
  }

  toggleShowGrid() {
    this.showGrid = !this.showGrid;
  }

  openSendModal() {
    this.sendModal.showModal();
  }

  openDepositModal() {
    this.depositModal.showModal();
  }

  openCoinDetails(asset) {
    this.coinDetailsModal.showModal(asset);
  }

}
