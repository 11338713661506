import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { AppSharedModule } from './shared/shared.module';
import { CoreModule } from './common/core.module';

// Components
import { AppComponent } from './app.component';
import { AppListComponent } from './pages/app-list/app-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DiscoverComponent } from './pages/discover/discover.component';
import { LaunchComponent } from './pages/launch/launch.component';
import { MiningComponent } from './pages/mining/mining.component';
import { VotingComponent } from './pages/voting/voting.component';
import { LayoutModule } from './layout/layout.module';

@NgModule({
  declarations: [
    AppComponent,
    AppListComponent,
    DashboardComponent,
    DiscoverComponent,
    LaunchComponent,
    MiningComponent,
    VotingComponent
  ],
  imports: [
    AppRoutingModule,
    AppSharedModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    LayoutModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
