import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { Account } from '../../models/account';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // Output
  @Output() account = new EventEmitter<Account>();
  @Output() loading = new EventEmitter<boolean>();

  // Properties
  password: string;

  constructor(
    private accountService: AccountService
  ) { }

  ngOnInit() {
  }

  login(password) {
    this.loading.emit(true);
    this.accountService.login(password)
    .subscribe((account) => {
      this.account.emit(account);
      this.loading.emit(false);
    });
  }
}
