import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Transaction } from '../models/transaction';
import { AccountService } from './account.service';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TransactionService {

  constructor(
    private accountService: AccountService,
    private httpClient: HttpClient
  ) { }

  getRecentTransactions() {
    return this.httpClient.get('./assets/json/recent-transactions.json');
  }

  getAllTransactions(limit?: number, offset?: number, sortField?: string, sortOrder?: string) {
    return forkJoin([this.getAssetTransactions(), this.getMyTransactions()])
      .pipe(
        map((data: any[]) => {
          data[0].transactions = data[0].transactions || [];
          data[1].transactions = data[1].transactions || [];

          const transactions = data[0].transactions.concat(data[1].transactions)
            .sort((a: Transaction, b: Transaction) => {
            return b.timestamp - a.timestamp;
          });

          return transactions.slice(0, limit);
      }));
  }

  getAssetTransactions(limit?: number, offset?: number, sortField?: string, sortOrder?: string) {
    sortField = sortField || 't_timestamp';
    sortOrder = sortOrder || 'desc';
    limit = limit || 5;
    offset = offset || 0;

    const url = 'https://wallet.nasgo.com/api/uia/transactions/' +
    `my/${this.accountService.account.address}?` +
    `orderBy=${sortField}:${sortOrder}&limit=${limit}&offset=${offset}`;

    return this.httpClient.get<Transaction[]>(url)
      .pipe(
        map((data: any) => {
          return data;
      }));
  }

  getMyTransactions(limit?: number, offset?: number, sortField?: string, sortOrder?: string) {
    sortField = sortField || 't_timestamp';
    sortOrder = sortOrder || 'desc';
    limit = limit || 5;
    offset = offset || 0;

    const url = 'https://wallet.nasgo.com/api/transactions?' +
      `recipientId=${this.accountService.account.address}&` +
      `senderPublicKey=${this.accountService.getPublicKey()}&` +
      `orderBy=${sortField}:${sortOrder}&limit=${limit}&offset=${offset}`;

    return this.httpClient.get<Transaction[]>(url)
      .pipe(
        map((data: any) => {
          return data;
      }));
  }

  getTransactionsByCoin() {
    return this.httpClient.get('./assets/json/coin-transactions.json');
  }

  getMyAssets() {
    return this.httpClient.get('./assets/json/my-assets.json');
  }
}
