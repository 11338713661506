import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'coinAmount' })
export class CoinAmountPipe implements PipeTransform {
  transform(value: number): string {
    const amount = (value / 100000000.0).toString();
    const amtArr = amount.split('.');

    if (amtArr.length === 2) {
      amtArr[0] = amtArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return amtArr.join('.');
  }
}
