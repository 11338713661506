import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-get-nsg-modal',
  templateUrl: './get-nsg-modal.component.html',
  styleUrls: ['./get-nsg-modal.component.scss']
})
export class GetNsgModalComponent implements OnInit {

  @ViewChild('getNsgModal') getNsgModal: any;

  constructor(
  ) { }

  showModal() {
    this.getNsgModal.show();
  }

  ngOnInit() {
  }

}
